/* eslint-disable angular/angular-module-imports */
import decodeHTMLEntities from '../utils/decodeHTMLEntities';
import {
  EVENT_CATEGORY_FOR_USER_FLOW,
  PROPERTY_FOR_USER_FLOW,
} from '../shop/constants/signupLoginEventsTracking.js';

// Alias for is.js, as "is" is a reserved word for coffee.js
window.isjs = window.is;
angular.lowercase = function (s) {
  return s && s.toLocaleLowerCase();
};

_.mixin({
  fetchpath: function (object, key, _default) {
    if (_default === undefined) {
      _default = null;
    }
    var props = key.split('.'),
      item = object;
    for (var i = 0; i < props.length; i++) {
      if (_.contains(_.keys(item), props[i])) {
        item = item[props[i]];
        if (i == props.length - 1) {
          return item;
        }
      }
    }
    return _default;
  },
});

document.querySelectorAll('.sl-unsafe').forEach(function (element) {
  element.setAttribute('ng-non-bindable', '');
});

window.addEventListener('pageshow', function (event) {
  if (event.persisted) {
    window.location.reload();
  }
});

// consts for checkout revamp AB testing
const COOKIE_NAME_FOR_AB_TEST = 'checkout_revamp';
const CHECKOUT_REVAMP_TRACKER_EXPERIMENT_NAME = 'checkout_revamp_cart_page';

var app = angular.module('shop_app', [
  'pascalprecht.translate',
  'ui.bootstrap',
  'ui.bootstrap.datetimepicker',
  'ngSanitize',
  'ngTouch',
  'ngCookies',
  'tw.translate-model',
  'tw.lightbox',
  'tw.translate-model',
  'tw.useragent',
  'creditcard-input.module',
  'angularFileUpload',
  'tw.filepicker',
  'tw.control-group',
  'tw.ladda',
  'tw.dynamic-name',
  'sl.hide-field-by-country',
  'sl.change-currency-filter',
  'angular-google-analytics',
  'sl.promotion-title-filter',
  'monospaced.qrcode',
]);

app.constant('cartTypes', {
  PROMOTION: 'PROMOTION',
});

app.constant('modalTypes', {
  QUICK_CART: 'QUICK_CART',
  QUICK_CART_PRODUCT_SET: 'QUICK_CART_PRODUCT_SET',
  MESSAGE_FORM: 'MESSAGE_FORM',
  TRAIL_ORDER_LIMIT: 'TRAIL_ORDER_LIMIT',
});

app.factory('httpRecaptchaInterceptor', [
  function () {
    return {
      request: function (config) {
        if (
          config.data &&
          typeof config.data === 'object' &&
          !!config.data.recaptchable
        ) {
          config.data['g-recaptcha-response'] = $(
            '#g-recaptcha-response',
          ).val();
          delete config.data.recaptchable;
        }

        return config;
      },
    };
  },
]);

app.factory('httpMissingSessionWarningInterceptor', [
  '$rootScope',
  '$cookies',
  'mainConfig',
  function ($rootScope, $cookies, mainConfig) {
    return {
      response: function (res) {
        if (
          !$rootScope.isIosIg ||
          $cookies.get('show_missing_session_key_warning') === 'false'
        )
          return res;

        if (
          (mainConfig.sessionId &&
            res.headers('Public-Session-Id') &&
            res.headers('Public-Session-Id') !== mainConfig.sessionId &&
            !/^\/api\/users/.test(res.config.url)) ||
          (!res.headers('Public-Session-Id') &&
            /^\/users\/auth\/facebook\/callback$/.test(res.config.url))
        ) {
          $rootScope.showMissingSessionKeyWarning = true;
          $cookies.put('show_missing_session_key_warning', 'true');
        }

        return res;
      },
    };
  },
]);

app.factory('httpSessionIsExpired', [
  '$q',
  '$window',
  function ($q, $window) {
    return {
      responseError: function (rejection) {
        if (
          rejection.status === 401 &&
          rejection.headers('X-Ajax-Redirect-Url')
        ) {
          const redirectUrl = rejection.headers('X-Ajax-Redirect-Url');
          $window.location.href = redirectUrl;
        }
        return $q.reject(rejection);
      },
    };
  },
]);

app.config([
  '$httpProvider',
  function ($httpProvider) {
    //need to comment out the below 2
    $httpProvider.defaults.withCredentials = true;
    $httpProvider.defaults.headers.common['X-CSRF-Token'] = $(
      'meta[name=csrf-token]',
    ).attr('content');

    //Enable cross domain calls
    $httpProvider.defaults.useXDomain = true;
    //Remove the header used to identify ajax call  that would prevent CORS from working
    delete $httpProvider.defaults.headers.common['X-Requested-With'];
    $httpProvider.interceptors.push('httpRecaptchaInterceptor');
    $httpProvider.interceptors.push('httpMissingSessionWarningInterceptor');
    $httpProvider.interceptors.push('httpSessionIsExpired');

    if (process.env.NODE_ENV === 'development') {
      $httpProvider.interceptors.push(function () {
        return {
          request: function (config) {
            if (config.url.startsWith('https://localhost:8080/packs/')) {
              config.withCredentials = false;
            }

            return config;
          },
        };
      });
    }
  },
]);

app.config([
  '$locationProvider',
  function ($locationProvider) {
    $locationProvider.html5Mode({
      enabled: true,
      rewriteLinks: false,
    });
  },
]);

app.config([
  '$sceProvider',
  function ($sceProvider) {
    $sceProvider.enabled(false);
  },
]);

app.config([
  '$sanitizeProvider',
  function ($sanitizeProvider) {
    $sanitizeProvider.addValidAttrs(['style']);
  },
]);

app.config([
  '$provide',
  '$locationProvider',
  function ($provide, $locationProvider) {
    $provide.decorator('$browser', [
      '$delegate',
      'slFeatureService',
      'userAgentService',
      function ($delegate, slFeatureService, userAgentService) {
        if (!slFeatureService.hasFeature('disable_html5mode')) return $delegate;

        if (!userAgentService.isiOS()) {
          $locationProvider.html5Mode(false).hashPrefix('');
        }

        var originalUrl = $delegate.url;

        $delegate.url = _.throttle(function () {
          var result = originalUrl.apply(this, arguments);

          if (result && result.replace) {
            result = result.replace(/%2F/g, '/');
          }

          return result;
        }, 2000);

        return $delegate;
      },
    ]);
  },
]);

app.run([
  'ANGULAR_LOCALE',
  '$locale',
  '$rootScope',
  '$window',
  '$translate',
  '$location',
  'mainConfig',
  'cartService',
  'trackerService',
  'featureService',
  'fbService',
  'categoryFilterService',
  'cookieConsentService',
  'currencyLanguageService',
  'slFeatureService',
  '$cookies',
  function (
    ANGULAR_LOCALE,
    $locale,
    $rootScope,
    $window,
    $translate,
    $location,
    mainConfig,
    cartService,
    trackerService,
    featureService,
    fbService,
    categoryFilterService,
    cookieConsentService,
    currencyLanguageService,
    slFeatureService,
    $cookies,
  ) {
    $translate.use(mainConfig.localeData.loadedLanguage.code);
    var fallbackLanguage =
      mainConfig.localeData.loadedLanguage.code == 'en' ? 'zh-hant' : 'en';
    $translate.fallbackLanguage(fallbackLanguage);

    // Force update on translations of angular template
    Object.assign($locale.DATETIME_FORMATS, ANGULAR_LOCALE.DATETIME_FORMATS);
    Object.assign($locale.NUMBER_FORMATS, ANGULAR_LOCALE.NUMBER_FORMATS);

    if (
      featureService.hasFeature('cross_shop_tracker') &&
      cookieConsentService.canUseCookie('statistical')
    ) {
      var iframe = document.createElement('iframe');
      iframe.setAttribute('id', 'shoplyticsIframe');
      iframe.setAttribute('src', mainConfig.shoplyticsIframeUrl);
      iframe.setAttribute('frameborder', '0');
      iframe.setAttribute('width', '0');
      iframe.setAttribute('height', '0');
      iframe.setAttribute('border', '0');
      iframe.setAttribute('scrolling', 'no');
      iframe.setAttribute('style', 'display: none');
      iframe.onload = function () {
        this.contentWindow.postMessage(
          {
            action: 'get',
            key: 'shoplytics_unique_id',
          },
          mainConfig.shoplyticsIframeUrl,
        );
      };

      document.querySelector('body').appendChild(iframe);

      window.addEventListener('message', parentMessageHandler, false);

      function parentMessageHandler(event) {
        if (!event.data) {
          return;
        }
        var action = event.data.action;
        var key = event.data.key;
        var value = event.data.value;

        if (action == 'returnData') {
          if (value == null) {
            $.get(
              mainConfig.shoplyticsApi +
                '?merchant_id=' +
                mainConfig.merchantId,
              function (data) {
                var shoplytics_unique_id = data.sid;
                window.localStorage.setItem(
                  'shoplytics_unique_id',
                  shoplytics_unique_id,
                );
                iframe.contentWindow.postMessage(
                  {
                    action: 'save',
                    key: key,
                    value: shoplytics_unique_id,
                  },
                  mainConfig.shoplyticsIframeUrl,
                );
              },
            );
          } else {
            window.localStorage.setItem('shoplytics_unique_id', value);
          }
        }
      }
    }

    /** @typedef {'variant' | 'control'} experimentVariant */
    const experimentStartVariant = $cookies.get(COOKIE_NAME_FOR_AB_TEST);
    // only trigger tracker in cart page
    if (window.location.pathname === '/cart' && experimentStartVariant) {
      const trackerData = {
        experiment_name: CHECKOUT_REVAMP_TRACKER_EXPERIMENT_NAME,
        variation: experimentStartVariant,
      };

      /**
       * Due to the shoplytics broadcast listeners are not initialized at this point,
       * calling shoplytics fn directly to prevent it.
       */
      trackerService.experimentStart(trackerData);
    }

    // avoid send pageView event twice in specific page
    if (trackerService.shouldSendDefaultPageView()) {
      trackerService.pageView();
    }

    //console.log("test");
    $rootScope.currentUser = mainConfig.currentUser;
    $rootScope.isUserLoggedIn = $rootScope.currentUser;

    cartService.merchantId = mainConfig.merchantId;
    $rootScope.currentCart = cartService;
    $rootScope.mainConfig = mainConfig;
    $rootScope.featureService = featureService;
    $rootScope._ = _;
    $rootScope.supportedCurrencies =
      mainConfig.currencyData.supportedCurrencies;

    var resizeAction = _.throttle(function () {
      window.resizeImages();
      $('.title-container.ellipsis').trigger('update.dot');
      $rootScope.$broadcast('window.resize');
    }, 1000);

    var resizeFb = _.throttle(
      function () {
        if (typeof FB !== 'undefined') {
          // re-render only specific non-responsive elements
          _.each(
            document.getElementsByClassName('fb-page-container'),
            function (element) {
              FB.XFBML.parse(element);
            },
          );
        }
      },
      1000,
      { trailing: false },
    );

    $(window).resize(resizeAction).load(resizeAction);

    if (is.desktop()) {
      $(window).resize(resizeFb);
    }

    $(window).on('orientationchange', resizeFb);

    $rootScope.changeLanguage = function (language) {
      if (featureService.hasFeature('locale_revamp')) {
        var location = $window.location;
        var searchParamsObj = new URLSearchParams(location.search);
        var path = location.protocol + '//' + location.host + location.pathname;
        path = String(path);

        // append locale query string first, and handle later if page support locale revamp
        searchParamsObj.set('locale', language);

        // delete next query, on url change
        searchParamsObj.delete('next');

        var locationPathname = location.pathname;
        var supportedLanguagesPath =
          mainConfig.merchantData.supported_languages.map(function (lang) {
            return '/' + lang + '/';
          });
        var languagePrefixPath =
          location.protocol + '//' + location.host + '/' + language;
        if (
          locationPathname.match(
            /(products|categories|pages|about|blog|promotions)/,
          )
        ) {
          if (
            mainConfig.merchantData.supported_languages.includes(
              locationPathname.split('/')[1],
            )
          ) {
            locationPathname =
              '/' + locationPathname.split('/').slice(2).join('/');
          }
          searchParamsObj.delete('locale');
          path = languagePrefixPath + locationPathname;
        }
        // home page
        else if (
          locationPathname === '/' ||
          supportedLanguagesPath.includes(locationPathname)
        ) {
          searchParamsObj.delete('locale');
          path = languagePrefixPath + '/';
        }

        var queryString = searchParamsObj.toString();
        path += queryString ? '?' + queryString : '';

        $window.location.href = path;
      } else {
        var currentQueryParams = $location.search();
        var categoryFilterTypes = categoryFilterService.getFilterTypes();
        var filterQueries = ['scope', 'next'];

        Object.keys(currentQueryParams).forEach(function (key) {
          var isFilterTag = /filter_tag/.test(key);
          if (
            categoryFilterTypes.includes(key) ||
            filterQueries.includes(key) ||
            isFilterTag
          ) {
            delete currentQueryParams[key];
          }
        });

        Object.assign(currentQueryParams, { locale: language });
        $location.search(currentQueryParams);
        window.location.href = $location.absUrl();
      }
    };

    $rootScope.currencyLabel = function (currency) {
      return Currency.moneyFormats[currency].money_format.replace(
        /\{\{amount\}\}/,
        ' ' + currency,
      );
    };

    $rootScope.changeCurrency = function (currency) {
      var oldCurrency =
        Currency.cookie.read() ||
        mainConfig.currencyData.requestCountryCurrencyCode;
      Currency.convertAll(
        oldCurrency,
        currency.toUpperCase(),
        '.price, .sl-price',
      );
      $rootScope.currentCurrency = currency.toUpperCase();
      $rootScope.$broadcast('main.currency', currency);
      $rootScope.$broadcast('cart.reload');
      if ($jq('#currency-panel').length > 0) {
        $.sidr('close', 'currency-panel');
      }
    };

    function rerenderProductsCurrency() {
      var currency =
        Currency.cookie.read() ||
        mainConfig.currencyData.requestCountryCurrencyCode;
      if (
        mainConfig.isMultiCurrencyEnabled &&
        currency != mainConfig.merchantData.base_currency_code
      ) {
        //replace all static price tag via selector
        Currency.convertAll(
          mainConfig.merchantData.base_currency_code,
          currency,
          '.price, .sl-price',
        );
      }
    }

    $rootScope.$on('shop.currency.reload', function () {
      rerenderProductsCurrency();
    });

    $('.datetime').each(function () {
      $(this).html(dayjs($(this).text()).format('YYYY-MM-DD hh:mmA'));
    });

    if (
      !(
        window.location.pathname.match(/^\/(checkout|confirm)/) ||
        window.location.pathname.match(/^\/orders\/[a-zA-Z0-9]+(\/confirm)?$/)
      )
    ) {
      rerenderProductsCurrency();
    }

    if (featureService.getSetting('facebook_chat_plugin')) {
      function onReceiveMessage(e) {
        if (e.origin === 'https://www.facebook.com') {
          fbService.setFacebookCustomerChatPosition();
        }
      }
      // Facebook-Customer-Chat trigger window.postMessage in its <iframe />.
      // We use it to detect if Facebook-Customer-Chat completed loading, and
      // set its position
      window.addEventListener('message', onReceiveMessage);
    }

    if (
      mainConfig.themeSettings.goto_top &&
      mainConfig.pageIdentifier !== 'one_page_store'
    ) {
      (function () {
        var isGoingToTop;

        var getViewportHeight = (function () {
          var target = window;
          var prefix = 'inner';

          if (!('innerWidth' in window)) {
            target = document.documentElement || document.body;
            prefix = 'client';
          }

          return function () {
            return target[prefix + 'Height'];
          };
        })();

        var $gotoTop = $('<div class="sl-goto-top js-goto-top"></div>');

        $gotoTop.click(function () {
          $gotoTop.hide();
          isGoingToTop = true;

          $('html, body').animate({ scrollTop: 0 }, 700, function () {
            isGoingToTop = false;
          });
        });

        $('body').append($gotoTop);

        function setGotoTopButton() {
          if (
            document.documentElement.scrollTop ||
            document.body.scrollTop > getViewportHeight()
          ) {
            $gotoTop.show();
          } else {
            $gotoTop.hide();
          }
        }

        var onScroll = _.throttle(function () {
          if (!isGoingToTop) {
            setGotoTopButton();
            fbService.setFacebookCustomerChatPosition();
          }
        }, 1000);

        window.addEventListener('scroll', onScroll);
        setGotoTopButton();
      })();
    }

    const isCartOrCheckoutPage = $('body').is('.cart,.checkout');
    if (mainConfig.isExceedCartLimitation && !isCartOrCheckoutPage) {
      $rootScope.$on('basic-popover.loaded', function () {
        $rootScope.$broadcast('cart.overLimit', { login: true });
      });
    }

    function filterSupportedLanguages(langs) {
      const supportedLanguagesByCurrency =
        currencyLanguageService.getCurrencyLanguage(
          mainConfig.merchantData.base_currency_code,
        );
      return langs.filter((lang) => {
        const featureExpression = `multi_lang_${lang.replace('-', '_')}`;
        return (
          supportedLanguagesByCurrency.includes(lang) ||
          featureService.hasFeature(featureExpression) ||
          slFeatureService.hasFeature(featureExpression)
        );
      });
    }

    function handleLanguageUrl() {
      const filteredSupportedLanguages = filterSupportedLanguages(
        mainConfig.merchantData.supported_languages,
      );
      const DEFAULT_LANGUAGE = 'en';
      const LANGUAGE_LIST = [
        'en',
        'zh-hant',
        'zh-cn',
        'th',
        'vi',
        'id',
        'ja',
        'ms',
        'fr',
        'de',
      ];

      if (featureService.hasFeature('locale_revamp')) {
        const location = $window.location;
        const localePathName = location.pathname.split('/')[1];
        if (!LANGUAGE_LIST.includes(localePathName)) {
          return;
        }

        if (
          localePathName &&
          !filteredSupportedLanguages.includes(localePathName)
        ) {
          const newUrl = location.href.replace(
            localePathName,
            DEFAULT_LANGUAGE,
          );
          window.location.href = newUrl;
        }
      } else {
        const currentQueryParams = $location.search();
        if (!LANGUAGE_LIST.includes(currentQueryParams.locale)) {
          return;
        }

        if (!filteredSupportedLanguages.includes(currentQueryParams.locale)) {
          Object.assign(currentQueryParams, { locale: DEFAULT_LANGUAGE });
          $location.search(currentQueryParams);
          window.location.href = $location.absUrl();
        }
      }
    }

    handleLanguageUrl();
  },
]);

app.controller('MainController', [
  '$rootScope',
  '$scope',
  'mainConfig',
  '$uibModal',
  'Analytics',
  'productService',
  'cartService',
  'trackerService',
  '$injector',
  '$location',
  'slPixelService',
  'pnotifyService',
  'flash',
  '$timeout',
  'modalTypes',
  'hiidoTrackerService',
  'featureService',
  '$cookies',
  'fbService',
  'userAgentService',
  'slFeatureService',
  'wishlistService',
  'backInStockService',
  function (
    $rootScope,
    $scope,
    mainConfig,
    $uibModal,
    Analytics,
    productService,
    cartService,
    trackerService,
    $injector,
    $location,
    slPixelService,
    pnotifyService,
    flash,
    $timeout,
    modalTypes,
    hiidoTrackerService,
    featureService,
    $cookies,
    fbService,
    userAgentService,
    slFeatureService,
    wishlistService,
    backInStockService,
  ) {
    if (flash.verification_message) {
      PNotify.removeAll();
      pnotifyService.notify(flash.verification_message, {});
    }

    if (flash.encrypt_data_alert) {
      PNotify.removeAll();
      pnotifyService.notify(flash.encrypt_data_alert, {
        customClass: 'error',
        icon: 'fa fa-exclamation-triangle',
      });
    }

    var currentCurrencyISO = (
      Currency.cookie.read() ||
      mainConfig.currencyData.requestCountryCurrencyCode ||
      ''
    ).toLowerCase();
    // Categories collapse
    if (
      !angular.isUndefined(mainConfig.themeSettings['categories_collapsed'])
    ) {
      $scope.defaultCollapse = mainConfig.themeSettings['categories_collapsed'];
    } else {
      $scope.defaultCollapse = true;
    }

    if (
      !angular.isUndefined(
        mainConfig.themeSettings['mobile_categories_collapsed'],
      )
    ) {
      $scope.defaultMobileCollapse =
        mainConfig.themeSettings['mobile_categories_collapsed'];
    } else {
      $scope.defaultMobileCollapse = true;
    }

    $scope.defaultMobileCategoriesRemoved =
      mainConfig.themeSettings['mobile_categories_removed'] || false;

    $scope.isExpanded = {};
    $scope.isCollapsed = function (categoryId) {
      if ($scope.isExpanded[categoryId] === undefined) {
        $scope.isExpanded[categoryId] = $scope.defaultCollapse !== true;
      }

      return !$scope.isExpanded[categoryId];
    };

    $rootScope.showMessageForm = function () {
      function initGrecaptcha() {
        // only need to init when in message modal
        if (
          $('.contact-us-modal').length === 0 ||
          $('.contact-us-modal .g-recaptcha').length === 0
        )
          return;
        var recaptchaBtn = document.querySelector(
          '.contact-us-modal .g-recaptcha',
        );
        var widgetId = window.grecaptcha.render(
          recaptchaBtn,
          {
            sitekey: mainConfig.recaptchaSiteKey,
          },
          true,
        );
        // workaround when repeatedly render recaptcha in modal:
        // since grecaptcha generates different recaptcha id every time it re-renders
        // and we always get val from #g-recaptcha-response
        $('.contact-us-modal .g-recaptcha').append(
          '<div id="g-recaptcha-response" class="hidden"></div>',
        );
        recaptchaBtn.dataset.widgetId = widgetId;
      }
      //$rootScope.dimissCurrentModal();
      $rootScope.currentModal = $uibModal.open({
        templateUrl: '/messages/new/modal',
        windowClass: 'contact-us-modal',
      });

      $rootScope.currentModal.opened = $rootScope.currentModal.opened.then(
        function () {
          $rootScope.$emit('modal.open', {
            modalType: modalTypes.MESSAGE_FORM,
          });
          fbService.hideFacebookCustomerChat();
        },
      );

      $rootScope.currentModal.rendered.then(function () {
        initGrecaptcha();
      });

      // when using mobile-logo's layout and in mobile size
      if ($(window).width() < 768 && $('.mobile-logo').length > 0) {
        // when open the modal
        $rootScope.currentModal.opened.then(function () {
          $('.panel-mobile').addClass('panel-zIndex');
          $('#fixed-menu-container.mobile-revamp-navigation').addClass(
            'fixed-menu-container-zIndex',
          );
        });
        // when close the modal
        $rootScope.currentModal.result.finally(function () {
          $('.panel-mobile').removeClass('panel-zIndex');
          $('#fixed-menu-container.mobile-revamp-navigation').removeClass(
            'fixed-menu-container-zIndex',
          );
        });

        $.sidr('close', 'left-panel');
      }
    };

    $rootScope.closeMessageForm = function () {
      $rootScope.currentModal.close();
    };

    // For cart reload when cart menu is going to slide out
    $scope.$on('cart.reload', function () {
      cartService.fetchItems(null, {
        skip_reapply_promotion: mainConfig.pageType !== 'cart-index',
      });
      hiidoTrackerService.cart.pageView();
    });

    var currentPageType = function () {
      var pageType = 'others';
      var pathname = window.location.pathname;
      if (/^\/(.*\/)?products(\/)?$/.test(pathname)) {
        var search = window.location.search;
        if (/query=/.test(search)) {
          pageType = 'search';
        } else {
          pageType = 'all_products';
        }
      } else if (/^\/(.*\/)?categories(.*)?/.test(pathname)) {
        pageType = 'category_page';
      } else if ($('body').is('.products.show')) {
        pageType = 'product';
      }

      return pageType;
    };

    $rootScope.sendGaProductClick = function (
      _id,
      sku,
      variations,
      title,
      rec_strategy,
    ) {
      //  Analytics.addProduct(productId, name, category, brand, variant, price, quantity, coupon, position, custom);
      // Analytics.productClick(listName);
      var variation = false;
      if (variations != null && variations.length > 0) {
        try {
          variation = JSON.parse(decodeHTMLEntities(variations[0]));
        } catch (error) {
          console.error(error);
        }
      }
      // values marked "" are either for phase2, or not marked as required on GA examples
      if (Analytics.configuration.enhancedEcommerce) {
        if (mainConfig.currentUser) {
          Analytics.set('&uid', mainConfig.currentUser._id);
        }
        Analytics.addProduct(
          productService.getSku(_id, sku, variation),
          title,
          '',
          '',
          productService.getVariationName(variation),
          '',
          '',
          '',
          '0',
          '',
        );
        Analytics.productClick('');
      }

      trackerService.track('ProductClick', {
        page_type: currentPageType(),
        product_id: _id,
        product_sku: productService.getSku(_id, sku, variation),
        product_title: title,
        product_variation_name: productService.getVariationName(variation),
        scope: trackerService.getTrackerScope(),
      });

      if ($('body').is('.products.show') && $injector.has('products')) {
        var basedProduct = $injector.get('product');
        var relatedProducts = $injector.get('products');
        var index = relatedProducts.findIndex(function (product) {
          return product.id === _id;
        });
        if (basedProduct && index !== -1) {
          var params = {
            product_id: _id,
            based_product_id: basedProduct._id,
            rank: index + 1,
            ...(rec_strategy && { rec_strategy }),
          };
          trackerService.track('RecommendItem', params);
          hiidoTrackerService.adaptRecommendProduct(params);
        }
      }

      if (trackerService.shouldSendQuickCartPageView()) {
        trackerService.pageView({
          data: {
            product_id: _id,
          },
        });
      }
    };

    $rootScope.scrollTo = function (element) {
      var top = element && element.offset() ? element.offset().top : 0;
      angular.element('html,body').animate({ scrollTop: top }, 300);
    };

    $scope.currentCurrency = _.find(
      $rootScope.supportedCurrencies,
      function (currency) {
        return currency.iso_code == currentCurrencyISO;
      },
    );

    $('.App-currencyDropdown .Label').html(
      '<span data-nosnippet>' +
        $scope.currentCurrency.symbol +
        '</span> <span data-nosnippet>' +
        $scope.currentCurrency.iso_code.toUpperCase() +
        '</span>',
    );

    $scope.$on('main.currency', function (event, currency_iso) {
      $scope.currentCurrency = _.find(
        $rootScope.supportedCurrencies,
        function (currency) {
          return currency.iso_code == currency_iso;
        },
      );
      $('.App-currencyDropdown .Label').html(
        '<span>' +
          $scope.currentCurrency.symbol +
          '</span> <span>' +
          $scope.currentCurrency.iso_code.toUpperCase() +
          '</span>',
      );
    });
    // Only add the overlayScroll bar whenthe content is loaded
    $timeout(function () {
      // V1
      $('.dropdown-menu.menu-button.currency').overlayScrollbars({});
      // V2
      $(
        '.NavigationBar-actionMenu-dropdown.dropdown-menu.sl-currency-chooser',
      ).overlayScrollbars({});
    });

    $scope.closeEnabledStoreNotify = function () {
      angular.element('.previewing-closed-store-banner').remove();
      angular
        .element('.has-previewing-closed-store-banner')
        .removeClass('has-previewing-closed-store-banner');
    };

    $scope.enableOwlCarouselPopUp = function () {
      var enableTheme = ['bianco', 'sangria'];
      var currentTheme = $rootScope.mainConfig.merchantData.current_theme_key;
      return enableTheme.includes(currentTheme);
    };

    var path = $location.path();
    var categoryID =
      path.match(/^\/categories\/.*/i) && flash
        ? flash.breadcrumb_category_id
        : null;

    hiidoTrackerService.trackingWithRoute();
    if (path === '/') {
      slPixelService.hdPageView('home');
    } else if (
      (path === '/products' && !$location.search().query) ||
      path === '/categories' ||
      categoryID
    ) {
      slPixelService.hdPageView('productList', { categoryID: categoryID });
    } else if (!path.match('/products') && path !== '/cart') {
      slPixelService.hdPageView('other');
    }

    var getParams = function (url) {
      var params = {};
      var parser = document.createElement('a');
      parser.href = url;
      var query = parser.search.substring(1);
      var vars = query.split('&');
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        params[pair[0]] = decodeURIComponent(pair[1]);
      }
      return params;
    };

    // DO NOT REMOVE
    // old referral UI below has been removed from codebase in SL-35111
    // but they still existed in unmigrated liquid of some merchants
    if (featureService.hasFeature('customer_referral')) {
      var slMref = localStorage.getItem('sl-mref');
      var slMrefPopupClick =
        localStorage.getItem('sl-mref-popup-click') === 'true';
      var slMrefParams = getParams(window.location.href)['sl-mref'];

      if (slMref) {
        if (slMrefParams && slMref !== slMrefParams) {
          localStorage.setItem('sl-mref', slMrefParams);
          localStorage.setItem('sl-mref-popup-click', false);
          $('.member-referral-customer-popup').removeClass('hidden');
        } else if (!slMrefPopupClick) {
          $('.member-referral-customer-popup').removeClass('hidden');
        }
      } else if (slMrefParams && !slMrefPopupClick) {
        localStorage.setItem('sl-mref', slMrefParams);
        $('.member-referral-customer-popup').removeClass('hidden');
      }
    }
    $(document).on(
      'click',
      '.member-referral-customer-popup-desktop .close-section',
      function () {
        $('.member-referral-customer-popup').addClass('hidden');
        localStorage.setItem('sl-mref-popup-click', true);
      },
    );
    $(document).on(
      'click',
      '.member-referral-customer-popup-desktop .action-button',
      function () {
        $('.member-referral-customer-popup').addClass('hidden');
        localStorage.setItem('sl-mref-popup-click', true);
      },
    );

    if ($rootScope.currentUser) {
      localStorage.removeItem('sl-mref');
      localStorage.removeItem('sl-mref-popup-click');
    } else {
      $cookies.remove('close_welcome_member_referral');
    }
    // END OF DO NOT REMOVE

    function openWelcomeMemberReferralCreditModal(value) {
      $uibModal
        .open({
          templateUrl: require('../../../../public/themes/v1/default/views/templates.dialog.welcome-member-referral-credits.html'),
          controller: [
            '$scope',
            '$uibModalInstance',
            '$rootScope',
            function ($scope, $uibModalInstance, $rootScope) {
              $scope.close = function () {
                $uibModalInstance.close();
                $cookies.put('close_welcome_member_referral', true);
              };

              $scope.value = value;

              $timeout(function () {
                $('.welcome-referral-modal-window').one('click', function () {
                  $cookies.put('close_welcome_member_referral', true);
                  $uibModalInstance.dismiss('cancel');
                });
                $rootScope.$emit('modal.open');
              });
            },
          ],
          windowClass: 'welcome-referral-modal-window',
          backdropClass: 'welcome-referral-modal-backdrop',
        })
        .result.catch(function (res) {
          if (res === 'backdrop click') {
            $cookies.put('close_welcome_member_referral', true);
          }
        });
    }

    if (
      featureService.hasFeature('customer_referral') &&
      $rootScope.currentUser &&
      flash.show_welcome_member_referral &&
      !$cookies.get('close_welcome_member_referral')
    ) {
      openWelcomeMemberReferralCreditModal(flash.show_welcome_member_referral);
    }
    // NOTE: hide FB messenger chat for their bug in cart page & member center page
    if (/^\/checkout|^\/users\/.*\/edit|^\/users\/sign_in/.test(path)) {
      console.log('we are going to hide fb root.');
      fbService.hideFacebookCustomerChat();
    }

    var sendProductImpression = function () {
      if (
        !document.body.classList.contains('products') ||
        !$injector.has('products')
      ) {
        return;
      }

      var productIds = $injector.get('products').map(function (product) {
        return product.id;
      });

      trackerService.track('ProductImpression', {
        page_type: currentPageType(),
        product_ids: productIds,
      });
    };

    sendProductImpression();

    const trackThirdPartyLoginMethod = () => {
      const urlParams = new URLSearchParams(window.location.search);
      if (!urlParams.toString()) {
        return;
      }

      const socialPlatform = urlParams.get('platform');
      const loginOrSignUp = urlParams.get('type');

      if (loginOrSignUp === 'signin' && socialPlatform) {
        trackerService.track({
          type: trackerService.generalEventType.MemberLoginSucceed,
          data: {
            event_category: EVENT_CATEGORY_FOR_USER_FLOW.MemberLogin,
            property: PROPERTY_FOR_USER_FLOW.login_option,
            value: socialPlatform,
          },
        });
      }
    };

    trackThirdPartyLoginMethod();

    $scope.isTcatMobile = function () {
      return userAgentService.isMobi() || userAgentService.isiOS();
    };
    $rootScope.isIosIg =
      userAgentService.isiOS() && userAgentService.isInstagramBrowser();
    $rootScope.showMissingSessionKeyWarning =
      $cookies.get('show_missing_session_key_warning') === 'true';

    if (
      slFeatureService.hasFeature('plp_wishlist') &&
      mainConfig.themeSettings.plp_wishlist &&
      $('product-item').length
    ) {
      if ($rootScope.isUserLoggedIn) {
        wishlistService
          .getList({
            merchant_id: mainConfig.merchantId,
            user_id: mainConfig.currentUser._id,
            limit: 1000,
            simplified: true,
          })
          .then(function (res) {
            $scope.wishlistItems = res.data.items;
            $scope.wishlistItemsLoaded = true;
            var cookieWishlistItem =
              $cookies.get('cookieWishlistItem') &&
              JSON.parse($cookies.get('cookieWishlistItem'));
            if (cookieWishlistItem) {
              let isItemSavedBefore;
              if (cookieWishlistItem.product_set_datas) {
                // is product set
                if (slFeatureService.hasFeature('product_set')) {
                  isItemSavedBefore = $scope.wishlistItems.find((item) => {
                    return (
                      item.product._id === cookieWishlistItem.product_id &&
                      item.product_set_datas?.every((childProduct, index) => {
                        return _.matches({
                          child_product_id:
                            cookieWishlistItem.product_set_datas[index]
                              ?.child_product_id,
                          child_variation_id:
                            cookieWishlistItem.product_set_datas[index]
                              ?.child_variation_id,
                        })(childProduct);
                      })
                    );
                  });
                } else if (slFeatureService.hasFeature('product_set_revamp')) {
                  isItemSavedBefore = $scope.wishlistItems.find((item) => {
                    return (
                      item.product._id === cookieWishlistItem.product_id &&
                      item.product_set_datas?.every((childProduct, index) => {
                        return _.matches({
                          child_product_id:
                            cookieWishlistItem.product_set_datas[index]
                              ?.child_product_id,
                          child_variation_id:
                            cookieWishlistItem.product_set_datas[index]
                              ?.child_variation_id,
                          quantity:
                            cookieWishlistItem.product_set_datas[index]
                              ?.quantity,
                        })(childProduct);
                      })
                    );
                  });
                }
              } else {
                // not product set
                isItemSavedBefore = cookieWishlistItem.variation_key
                  ? $scope.wishlistItems.some(function (item) {
                      return (
                        item.product._id === cookieWishlistItem.product_id &&
                        item.product.variation.key ===
                          cookieWishlistItem.variation_key
                      );
                    })
                  : $scope.wishlistItems.some(function (item) {
                      return item.product._id === cookieWishlistItem.product_id;
                    });
              }

              if (isItemSavedBefore) {
                $rootScope.cookieWishlistItem = cookieWishlistItem;
                $cookies.remove('cookieWishlistItem');
              } else {
                productService
                  .getById(cookieWishlistItem.product_id)
                  .then(function (response) {
                    var product = response.data.data;
                    var variation = product.variations
                      ? product.variations.find(function (variation) {
                          return (
                            variation.key === cookieWishlistItem.variation_key
                          );
                        })
                      : null;
                    if (
                      backInStockService.isBackInStockAvailable &&
                      backInStockService.shouldShowBtn(product, variation)
                    ) {
                      cookieWishlistItem.is_subscribed_notify = true;
                    }
                    wishlistService
                      .addItem(cookieWishlistItem)
                      .then(function () {
                        $rootScope.cookieWishlistItem = cookieWishlistItem;
                        $cookies.remove('cookieWishlistItem');
                      });
                  });
              }
            }
          });
      } else {
        $scope.wishlistItems = [];
        $scope.wishlistItemsLoaded = true;
      }
    }
  },
]);

window.app = app;
